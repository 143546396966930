import { createRouter, createWebHistory } from 'vue-router'
import AboutView from '../views/AboutView.vue'

const routes = [
  {
    path: '/',
    name: 'about',
    component: AboutView,
    meta: {
      title: 'zsolt. | About'
    }
  },
  {
    path: '/setup',
    name: 'setup',
    meta: {
      title: 'zsolt. | Setup'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/SetupView.vue')
  },
  {
    path: '/hyped',
    name: 'hyped',
    meta: {
      title: 'zsolt. | Hyped'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/HypedView.vue')
  },
  {
    path: '/social',
    name: 'social',
    meta: {
      title: 'zsolt. | Social'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/SocialView.vue')
  },
  {
    path: '/posts',
    name: 'posts',
    meta: {
      title: 'zsolt. | Posts'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PostsView.vue')
  },
  {
    path: '/overdrive',
    name: 'RayTracing',
    meta: {
      title: 'zsolt. | Cyberpunk 2077 Overdrive mode'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Misc/RayTracing.vue')
  },
  {
    path: '/redeem',
    name: 'RedeemView',
    meta: {
      title: 'zsolt. | Redeem'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/RedeemView2.vue')
  }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // Get the page title from the route meta data that we have defined
  // See further down below for how we setup this data
  const title = to.meta.title
// If the route has a title, set it as the page title of the document/page
  if (title) {
    document.title = title
  }
  // Continue resolving the route
  next()
})

export default router
